* {
    box-sizing: border-box;
}

body {
    margin: 0;
}

.aLine {
    width: 100%;
    height: 45px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
}

.aLine::after,
.aLine::before {
    width: 50%;
    height: 2px;
    content: '';
    position: absolute;
    background: #bbb;
}

.aLine::after {
    left: 15%
}

.aLine::before {
    right: 15%
}

.vLine {
    background-color: #cbd6d6;
    display: flex;
    width: 2px;
    border-radius: 10%;

}

.blinkersOrange {
    animation: blink 1s linear infinite;
}

.container {
    display: flex;
    justify-content: center;
    background-image: url("../../pages/img/beige-paper.png");
}