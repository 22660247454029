.nav-container {
    width: 80%;
    color: #24354B;
    font-size: 4.5rem;
    text-align: center;
    margin-top: 0.5rem;
    border-radius: 0.5rem;
    font-family: VarelaRound;
    background-color: #D9D9D9;
}

.nav-container2 {
    gap: 0.8rem;
    width: 80%;
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    flex-direction: column;
}

.nav {
    top: 0;
    gap: 0.8rem;
    width: 150px;
    height: 100vh;
    position: absolute;
    z-index: 700;
    display: flex;
    position: fixed;
    align-items: center;
    flex-direction: column;
    background-color: #24354B;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.navLine {
    width: 100%;
    height: 0.1rem;
    background-color: #D9D9D9;
}

.navLink-Container {
    width: 100%;
    display: flex;
    color: #24354B;
    text-align: center;
    border-radius: 0.5rem;
    background-color: #D9D9D9;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.navLink-Container:active {
    transform: scale(0.95);
}

.navLinks {
    gap: 0.5rem;
    font-size: 1.1rem;
    align-items: center;
    flex-direction: row;
    display: inline-flex;
}

.nav a {
    height: 100%;
    color: inherit;
    display: block;
    padding: .25rem;
    font-weight: bold;
    align-items: center;
    text-decoration: none;
}

.loadingImg {
    max-height: 10rem;
}

/* .site-title-initial {
    font-family: ChineseStroke;
} */

@media (max-width: 580px) {
    .nav {
        width: 100%;
        height: auto;
        padding: 0.5rem;
        flex-direction: row;
    }

    .navLine {
        height: 0;
    }

    .navLinks {
        font-size: 1rem;
    }
}