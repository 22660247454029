.WarningPage
{
    height: 100vh;
    display: flex;
    margin-left: 150px;
    justify-content: center;
    align-items: safe center;
}
.warningpage-bodyContainer
{
    gap: 1rem;
    width: 100%;
    height: auto;
    display: flex;
    padding: 1rem;
    margin: 0.8rem;
    border-radius: 0.8rem;
    flex-direction: column;
    background-color: #24354b49;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.warningpage-textContainer
{
    gap: 1.5rem;
    width: 100%;
    height: auto;
    display: flex;
    font-family: VarelaRound;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}
.warningpage-text 
{
    font-size: 1.3rem;
    color: #24354B;
}
.warningpage-textBold
{
    font-size: 1.5rem;
    font-weight: bolder;
    color: #24354B;
}