.HomePage {
    float: left;
    width: 100%;
    display: flex;
    min-height: 100vh;
    margin-left: 150px;
    flex-direction: column;
}

.HomePage-Container {
    gap: 2rem;
    display: flex;
    margin: 1rem 1rem;
    padding: 1rem 1rem;
    flex-direction: column;
}

.HomePage-GroupContainer {
    gap: 0.5rem;
    width: 100%;
    display: flex;
    padding: 0.4rem;
    padding-top: 0.5rem;
    border-radius: 0.5rem;
    flex-direction: column;
    background-color: #192a35;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.HomePage-GroupName {
    gap: 1rem;
    display: flex;
    color: #cbd6d6;
    font-size: 1.5rem;
    align-items: center;
    padding-left: 0.3rem;
    font-family: VarelaRound;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.356);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.HomePage-SubGroupMainContainer {
    gap: 1rem;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 0.5rem;
    border-radius: 0.3rem;
    flex-direction: row;
    background-color: #def2ffd3;
}

.HomePage-SubGroupIndivContainer {
    width: 250px;
    height: 70px;
    display: flex;
    cursor: pointer;
    flex-direction: row;
    border-radius: 0.5rem;
    padding: 0.6rem 0.6rem;
    background-color: #24354b49;
}

.HomePage-SubGroupTitlePanel, .HomePage-SubGroupTitlePanelOffline {
    gap: 1rem;
    height: 100%;
    width: 100%;
    display: flex;
    color: #24354B;
    align-items: center;
    text-decoration: none;
    /* justify-content: space-between; */
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.HomePage-SubGroupTitlePanelOffline {
    opacity: 0.5;
}

.HomePage-SubGroupAccessIco {
    display: flex;
    font-size: 2.5rem;
}

.HomePage-SubGroupImage {
    width: 50px;
    height: 50px;
    object-fit: fill;
    border-radius: 0.5rem;
}

.HomePage-SubGroupName {
    color: #24354B;
    font-family: VarelaRound;
}

.HomePage-SubGroupMoreContainer {
    /* border: solid; */
    position: absolute;
    margin-top: 2.2rem;
    margin-left: 13.5rem;
}

.HomePage-SubGroupMoreIco, .HomePage-SubGroupMoreIcoOffline {
    display: flex;
    font-size: 1.2rem;
    position: relative;
    border-radius: 0.3rem;
}

.HomePage-SubGroupMoreIcoOffline {
    opacity: 0.5;
}

.HomePage-SubGroupOnHover {
    display: none;
}

.HomePage-SubGroupMoreIco:hover+.HomePage-SubGroupOnHover {
    top: -1.6rem;
    left: 1.2rem;
    display: inline;
    position: relative;
    padding: 0rem 0.2rem;
    border-radius: 0.3rem;
    background-color: #ffffff;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.HomePage-SubGroupMoreMenu {
    left: 0rem;
    gap: 0.5rem;
    bottom: 0rem;
    display: flex;
    padding: 0 0.5rem;
    position: relative;
    border-radius: 0.5rem;
    flex-direction: column;
    background-color: #ffffff;
}

.HomePage-SubGroupMoreName {
    text-wrap: nowrap;
    text-decoration: underline;
    text-underline-offset: 0.3rem;
}

@media (max-width: 580px) {
    .HomePage {
        margin-top: 9rem;
        margin-left: 0;
    }

    .HomePage-SubGroupIndivContainer {
        width: 7rem;
        height: 9rem;
    }

    .HomePage-SubGroupTitlePanel, .HomePage-SubGroupTitlePanelOffline {
        gap: 0.8rem;
        flex-direction: column;
        justify-content: center;
    }

    .HomePage-SubGroupAccessIco {
        font-size: 0;
    }

    .HomePage-SubGroupImage {
        width: 60px;
        height: 60px;
        object-fit: fill;
        border-radius: 0.5rem;
    }

    .HomePage-SubGroupName {
        text-align: center;
    }

    .HomePage-SubGroupMoreIco, .HomePage-SubGroupMoreIcoOffline {
        margin-top: 6.8rem;
        margin-left: 5.2rem;
    }

    .HomePage-SubGroupMoreContainer {
        /* border: solid; */
        position: absolute;
        margin-top: 0.2rem;
        margin-left: -0.1rem;
    }

    .HomePage-SubGroupMoreMenu {
        left: 4rem;
    }

    .HomePage-SubGroupMoreIco:hover+.HomePage-SubGroupOnHover {
        top: -1.8rem;
        left: 6.5rem;
    }
    
}